import { SVGProps, memo } from "react";

const SvgPen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 26}
    height={props.height || 26}
    {...props}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_12257_77465)">
      <path
        d="M3.87604 19.1159C3.92198 18.7024 3.94496 18.4957 4.00751 18.3025C4.06301 18.131 4.14143 17.9679 4.24064 17.8174C4.35246 17.6478 4.49955 17.5008 4.79373 17.2066L18 4.0003C19.1046 2.89573 20.8955 2.89573 22 4.0003C23.1046 5.10487 23.1046 6.89573 22 8.0003L8.79373 21.2066C8.49955 21.5008 8.35245 21.6479 8.18289 21.7597C8.03245 21.8589 7.86929 21.9373 7.69785 21.9928C7.5046 22.0553 7.29786 22.0783 6.88437 22.1243L3.5 22.5003L3.87604 19.1159Z"
        stroke="#343131"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_12257_77465"
        x="-1"
        y="-1"
        width="28"
        height="28"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="2"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_12257_77465"
        />
        <feOffset />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.01 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_12257_77465"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_12257_77465"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const Memo = memo(SvgPen);
export default Memo;
