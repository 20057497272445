import { Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "src/store";

interface AddToCartButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  packetId: string;
}

export const AddToCartButton = ({
  onClick,
  packetId,
}: AddToCartButtonProps) => {
  const { t } = useTranslation();
  const isExistInCard = useSelector((state: RootState) =>
    state.CartSlice.items.find((_) => _.data._id === packetId)
  );

  if (isExistInCard)
    return (
      <Typography color="success.main" variant="desktopSubtitle2">
        {t("packet:exist-in-cart")}
      </Typography>
    );

  return (
    <Button
      fullWidth
      size="medium"
      variant="text"
      color="primary"
      onClick={onClick}
      sx={{
        borderRadius: (theme) => theme.shape.borderRadius,
        color: (theme) => theme.palette.primary.dark,
      }}
    >
      {t("packet:add-to-basket")}
    </Button>
  );
};
