import {
  BeijeDotPadsBackground,
  BeijeDotPantylinerBackground,
  BeijeDotTamponBackground,
} from "@beije/react-icons";
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Link from "next/link";
import { useTranslation } from "next-i18next";

import { SafeImage } from "../../Common/SafeImage";

type PacketTypeProps = {
  packetType: IPacketType;
  children: React.ReactNode;
};

const PacketType = ({ packetType, children }: PacketTypeProps) => {
  const myTheme = useTheme();
  const mediumBreakpoint = useMediaQuery(myTheme.breakpoints.down("md"));
  const { i18n, t } = useTranslation();

  const handleVisibleBackgroundDot = (type: string) => {
    switch (type) {
      case "pads":
        return (
          <BeijeDotPadsBackground
            style={{
              position: "absolute",
              bottom: 100,
              zIndex: 0,
              right: 0,
              left: 0,
              width: "100%",
            }}
          />
        );
      case "pantyliner":
        return (
          <BeijeDotPantylinerBackground
            style={{
              position: "absolute",
              bottom: 100,
              zIndex: 0,
              right: 0,
              left: 0,
              width: "130%",
              height: "50vh",
            }}
          />
        );
      case "tampons":
        return (
          <BeijeDotTamponBackground
            style={{
              position: "absolute",
              bottom: 100,
              zIndex: 0,
              right: 0,
              left: 0,
              width: "135%",
              height: "50vh",
            }}
          />
        );
      default:
        return "";
    }
  };

  return (
    <Box
      component="section"
      width="100%"
      overflow="hidden"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 5,
        paddingY: 5,
        backgroundColor: myTheme.palette.background.paper,
        my: 6,
        position: "relative",
      }}
    >
      <Box display={{ xs: "block", sm: "none" }}>
        {handleVisibleBackgroundDot(packetType?.slug)}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
          textAlign: "center",
        }}
      >
        <Container maxWidth="md">
          <Box display={{ xs: "block", md: "none" }}>
            <SafeImage
              src={`/images/packets/${packetType?.slug}.png`}
              alt={packetType?.slug}
              width={200}
              height={200}
            />
          </Box>

          <Typography
            component="h2"
            variant={mediumBreakpoint ? "mobileH2" : "desktopH2"}
            sx={{
              color: "rgba(0, 0, 0, 0.9)",
            }}
          >
            {packetType?.name[i18n.language]}
          </Typography>
          {packetType?.description && (
            <Typography
              component="p"
              variant="desktopBody1"
              sx={{ mb: 4, color: "rgba(0, 0, 0, 0.6)" }}
            >
              {packetType?.description[i18n.language]}
            </Typography>
          )}
        </Container>
      </Box>
      <Box position="relative">
        <Container maxWidth="md">
          <Grid
            sx={{ zIndex: 2 }}
            container
            spacing={2}
            justifyContent="center"
          >
            {children}
          </Grid>
        </Container>
      </Box>

      <Link href={`/packets/${packetType?.slug}`}>
        <Button
          variant="contained"
          sx={{
            p: 2,
            textTransform: "none",
          }}
        >
          <Typography
            component="span"
            variant="button"
            sx={{ textTransform: "capitalize" }}
          >
            {t("products:view-more")}
          </Typography>
        </Button>
      </Link>
    </Box>
  );
};

export default PacketType;
