import { SVGProps, memo } from "react";

const SvgBeijeDotTamponBackground = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="375"
    height="663"
    viewBox="0 0 375 663"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M364.631 640.259C265.625 697.127 150.46 633.641 41.8649 598.617C-47.2228 569.885 -135.965 540.29 -187.461 462.203C-248.67 369.385 -306.471 249.367 -252.698 151.941C-200.352 57.1042 -67.5287 68.5653 39.4267 51.1359C131.43 36.143 222.695 17.4448 304.996 61.0598C411.403 117.449 524.129 194.786 536.458 314.534C549.676 442.911 476.68 575.901 364.631 640.259Z"
      fill="#693566"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M332.107 196.836C299.949 199.619 278.571 166.496 259.153 140.705C240.681 116.168 219 88.0987 228.175 58.7868C236.97 30.6865 271.485 24.2564 298.933 13.6129C322.396 4.51503 346.699 -5.68772 370.012 3.78761C394.96 13.9274 415.019 36.0093 419.416 62.5821C423.573 87.7074 404.665 107.966 390.781 129.314C373.678 155.614 363.358 194.131 332.107 196.836Z"
      fill="#3C223C"
    />
  </svg>
);

const Memo = memo(SvgBeijeDotTamponBackground);
export default Memo;
