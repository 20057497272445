import { theme } from "src/themes";

export const CardSx = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  height: "100%",
  borderRadius: 2,
  padding: 0,
  boxShadow: "none",
  backgroundColor: "#fff",
  transition: "all 400ms",
  borderTopRightRadius: 12,
  borderTopLeftRadius: 12,
  "&:hover": {
    boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.05)",
  },
};

export const CardContentSx = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  height: "100%",
  padding: 0,
};

export const CardMediaSx = {
  width: "100%",
  height: "100%",
  minHeight: "184px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  backgroundColor: theme.palette.grey[200],
  borderTopRightRadius: 12,
  borderTopLeftRadius: 12,

  "& img": {
    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,
  },
};

export const refreshBadgeSx = {
  display: "flex",
  transition: "all ease-in 400ms",
  position: "absolute",
  top: 24,
  right: 24,
  alignItems: "center",
  justifyContent: "center",
  gap: "6px",
  padding: "8px",
  paddingLeft: "14px",
  paddingRight: "16px",
  backgroundColor: "#FFFFFF",
  borderRadius: 16,
  [theme.breakpoints.down("md")]: {
    opacity: 1,
  },

  "& span": {
    fontSize: 12,
    minHeight: "100%",
    alignSelf: "center",
    paddingTop: 0.2,
    justifyContent: "center",
    fontWeight: 400,
  },
};

export const CardMediaIconsSx = {
  position: "absolute",
  left: 0,
  bottom: 0,
  padding: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
};

export const CardMediaIconSx = {
  background: "rgba(255, 255, 255, 0.5)",
  padding: 2,
  backdropFilter: "blur(16px)",
  borderRadius: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const CardContentSecondarySx = {
  display: "flex",
  flexDirection: "row",
  height: "100%",
  width: "100%",
  gap: "1.5rem",
};

export const CardActionsSx = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
};
