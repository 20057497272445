import { SVGProps, memo } from "react";

const SvgBeijeDotPantylinerBackground = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="298"
    height="612"
    viewBox="0 0 298 612"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M334.405 21.7154C412.875 31.367 491.333 45.017 544.504 103.796C602.242 167.624 635.711 252.84 620.22 337.675C603.947 426.79 542.252 497.573 464.285 543.1C376.588 594.309 272.073 636.99 179.69 594.913C85.6204 552.068 49.1391 443.982 25.0895 343.064C0.607787 240.333 -24.9882 118.674 48.1028 42.7396C118.089 -29.969 234.478 9.42451 334.405 21.7154Z"
      fill="#CE7328"
    />
  </svg>
);

const Memo = memo(SvgBeijeDotPantylinerBackground);
export default Memo;
