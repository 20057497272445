import { Pen, Refresh } from "@beije/react-icons";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { MouseEventHandler, useState } from "react";
import { useDispatch } from "react-redux";

import { Price } from "src/components/Common";
import { PacketName } from "src/components/Packet/PacketName";
import { OUT_OF_STOCK_PACKETS_SUB_TYPE } from "src/constant";
import { useAnalytics } from "src/core/analytics";
import {
  openCartDrawer,
  addToCart,
  toggleCustomizeModalOpen,
  PurchaseType,
} from "src/store/slices";

import { AddToCartButton } from "./AddToCartButton";
import {
  CardContentSecondarySx,
  CardContentSx,
  CardMediaSx,
  CardSx,
  refreshBadgeSx,
} from "./styles";
import { BackendImage } from "../../Common/BackendImage";

type PacketProps = {
  item: IPacket;
  purchaseType: PurchaseType;
};

export const Packet = ({ item, purchaseType }: PacketProps) => {
  const theme = useTheme();
  const mediumBreakpoint = useMediaQuery(theme.breakpoints.up("md"));

  const [isMouseEnter, setMouseEnter] = useState<boolean>(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { customTrackers } = useAnalytics();

  const singleItemPrice = item.price[purchaseType];

  const handleAddToBasketButtonClick: MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.stopPropagation();
    customTrackers.trackAddToCart({
      data: item,
      purchaseType,
      type: "packet",
    });
    dispatch(
      addToCart({
        data: item,
        purchaseType,
        type: "packet",
      })
    );
    dispatch(openCartDrawer());
  };

  const handleCustomizeButtonClick: MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.stopPropagation();
    dispatch(
      toggleCustomizeModalOpen({
        packet: item,
        action: "ADD_TO_CART",
      })
    );
  };

  const handleHoverOn = () => {
    setMouseEnter(true);
  };
  const handleHoverOff = () => {
    setMouseEnter(false);
  };

  return (
    <Card
      sx={CardSx}
      style={{
        overflow: "visible",
      }}
      onMouseEnter={handleHoverOn}
      onMouseLeave={handleHoverOff}
    >
      <CardContent sx={CardContentSx}>
        <CardMedia
          style={{
            cursor: "pointer",
          }}
          sx={CardMediaSx}
        >
          <Link href={`/packet/${item.slug}`}>
            <BackendImage
              src={{
                entity: "packet",
                idType: "slug",
                id: item.slug,
                variant: "card",
              }}
              loading="lazy"
              fill
              sizes="100vw"
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
            <Box sx={{ ...refreshBadgeSx, opacity: isMouseEnter ? 1 : 0 }}>
              <Refresh />
              <Typography component="span">{t("packet:bimonthly")}</Typography>
            </Box>
          </Link>
        </CardMedia>

        <CardContent
          sx={CardContentSecondarySx}
          style={{
            padding: "24px 24px 8px",
            cursor: "pointer",
          }}
        >
          <Link href={`/packet/${item.slug}`}>
            <Typography
              whiteSpace="break-spaces"
              component="p"
              variant="mobileH4"
              sx={{
                width: "100%",
              }}
            >
              <PacketName packetItems={item.items} breakLine />
            </Typography>
          </Link>
        </CardContent>
        <CardContent
          sx={{
            paddingBottom: 0,
            paddingY: 0,
            paddingX: 3,
          }}
        >
          <Typography component="p" variant="body2">
            <Price value={singleItemPrice} />
          </Typography>
        </CardContent>

        <CardActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: 3,
            gap: 1,
            width: "100%",
            paddingBottom: 0,
          }}
        >
          {item.items.filter((packet) =>
            OUT_OF_STOCK_PACKETS_SUB_TYPE.includes(packet._productSubType)
          ).length ? (
            <Typography
              component="p"
              variant="desktopSubtitle2"
              color="rgba(0, 0, 0, 0.26)"
              mb={1}
            >
              {t("packet:out-of-stock")}
            </Typography>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: 1,
                  flexGrow: 1,
                }}
              >
                <IconButton onClick={handleCustomizeButtonClick}>
                  <Pen
                    width={!mediumBreakpoint ? 20 : 24}
                    height={!mediumBreakpoint ? 20 : 24}
                  />
                </IconButton>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: 1.5,
                  flexGrow: 0,
                }}
              >
                <AddToCartButton
                  onClick={handleAddToBasketButtonClick}
                  packetId={item._id}
                />
              </Box>
            </>
          )}
        </CardActions>
      </CardContent>
    </Card>
  );
};
