import { ChevronLeft } from "@beije/react-icons";
import { Box, Button, Container, Typography } from "@mui/material";
import Image from "next/image";
import { useRouter } from "next/router";
import { FC } from "react";

import headerStyle from "./Header.module.scss";

type HeaderProps = {
  title: string;
  desc?: string;
  image: string | any;
  backButton?: {
    name: string;
  };
};

const Header: FC<HeaderProps> = ({ title, desc, image, backButton }) => {
  const router = useRouter();

  const handleBackClick = () => {
    router.push("/packets");
  };

  return (
    <>
      {backButton !== undefined && (
        <Container
          sx={{
            paddingTop: "1rem",
          }}
          component="section"
          maxWidth="lg"
        >
          <Button
            variant="text"
            onClick={handleBackClick}
            startIcon={<ChevronLeft width={24} height={24} />}
          >
            <span>{backButton?.name}</span>
          </Button>
        </Container>
      )}
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: "4rem",
          paddingBottom: "4rem",
        }}
        component="section"
        maxWidth="lg"
      >
        <Box>
          <Typography component="h4" variant="h4" marginBottom={2}>
            {title}
          </Typography>
          {desc && (
            <Typography
              maxWidth={860}
              component="p"
              variant="body2"
              className={headerStyle.desc}
            >
              {desc}
            </Typography>
          )}
        </Box>

        <Box>
          <Image
            src={image}
            alt={title}
            width={264}
            height={264}
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </Box>
      </Container>
    </>
  );
};

export default Header;
