import { FilterLines, XClose } from "@beije/react-icons";
import {
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
  Badge,
} from "@mui/material";
import { useTheme, Theme } from "@mui/material/styles";

import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { INTENSITIES } from "src/constant";
import { useAnalytics } from "src/core/analytics";
import { RootState } from "src/store";
import {
  useGetPacketsQuery,
  useGetPacketTypesQuery,
  useGetProductSubTypesQuery,
} from "src/store/apis";
import {
  applyFilter,
  fetchPacketTypes,
  fetchProductSubTypes,
  toggleFilteredByIDS,
  setFilterLoading,
  setFilterTotalItems,
  setFilterPage,
  setSelectedFilterCount,
} from "src/store/slices";
import { createFilterQuery } from "src/utils/packet";

import FilterCategory from "../FilterCategory";

type FilterProps = {
  showPacketTypes?: boolean;
  selectedProductTypes?: string;
};

const Filter = ({
  showPacketTypes = true,
  selectedProductTypes,
}: FilterProps) => {
  const tm = useTheme();
  const mediumBreakpoint = useMediaQuery(tm.breakpoints.up("md"));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { data: packetTypes } = useGetPacketTypesQuery();
  const { data: productSubTypes } = useGetProductSubTypesQuery();

  const cleanedPacketTypes = useSelector(() => fetchPacketTypes(packetTypes));
  const cleanedProductSubTypes = useSelector(() =>
    fetchProductSubTypes(productSubTypes)
  );

  // filter product sub types that has product type
  // (just product types wich we show in filter top section)
  const allProductTypesInFilter = cleanedPacketTypes
    ?.map((item) => item.productType)
    ?.filter(Boolean);
  const filteredProductSubTypesByPacketsProductType =
    cleanedProductSubTypes?.filter((item) =>
      allProductTypesInFilter?.includes(item.typeId)
    );

  // sort with backend sortOrder key
  const sortedPacketTypes = cleanedPacketTypes
    ?.filter((item) => item.algorithm === "ProductType")
    ?.sort((a, b) => a.sortOrder - b.sortOrder);

  const sortedProductSubTypes =
    filteredProductSubTypesByPacketsProductType?.sort(
      (a, b) => a.sortOrder - b.sortOrder
    );

  const filteredPackets = useSelector(
    (state: RootState) => state.FilterPacketSlice.filteredPackets
  );
  const filterPage = useSelector(
    (state: RootState) => state.FilterPacketSlice.filterPage
  );

  const selectedFilterCount = useSelector(
    (state: RootState) => state.FilterPacketSlice.selectedFilterCount
  );

  const {
    filterPacketTypeByID,
    filterProductSubTypeByID,
    filterIntensityByID,
  } = useSelector((state: RootState) => state.FilterPacketSlice);

  const [filterQuery, setFilterQuery] = useState<string>("");

  const { data: packetsData, isFetching: packetIsFetching } =
    useGetPacketsQuery({
      filter: filterQuery,
      page: filterPage,
    });

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const handleFilterByPacketType = (id: string) => {
    dispatch(toggleFilteredByIDS({ id, type: "filterPacketTypeByID" }));
  };

  const handleFilterByProductSubTypesChange = (id: string) => {
    dispatch(toggleFilteredByIDS({ id, type: "filterProductSubTypeByID" }));
  };

  const handleFilterByIntentisitiesChange = (id: string) => {
    dispatch(toggleFilteredByIDS({ id, type: "filterIntensityByID" }));
  };

  const noneFilterSelected =
    !filterPacketTypeByID?.length &&
    !filterProductSubTypeByID?.length &&
    !filterIntensityByID?.length;

  const { customTrackers } = useAnalytics();
  const handleAnalyticsEvent = () => {
    const url = window?.location.href;
    const filters = [
      ...filterPacketTypeByID.map((item: string) => ({
        type: "summary._productType",
        value: item,
      })),
      ...filterProductSubTypeByID.map((item: string) => ({
        type: "summary._productSubType",
        value: item,
      })),
      ...filterIntensityByID.map((item: string) => ({
        type: "intensityLevels",
        value: item,
      })),
    ];
    customTrackers.trackPacketFilter(url, filters);
  };

  const handleFilterClick = () => {
    if (noneFilterSelected) return;

    dispatch(
      setSelectedFilterCount(
        filterPacketTypeByID.length +
          filterProductSubTypeByID.length +
          filterIntensityByID.length
      )
    );

    if (filterPage > 0) {
      // clear prev filter if exist
      dispatch(setFilterPage(0));
    }
    handleAnalyticsEvent();

    const notSelectedPacketTypesId = sortedPacketTypes
      ?.map((item) => item.productType)
      ?.filter((item = "") => !filterPacketTypeByID?.includes(item));

    const notSelectedProductSubTypesId = sortedProductSubTypes
      ?.map((item) => item.value)
      ?.filter((item) => !filterProductSubTypeByID?.includes(item));

    setFilterQuery(
      createFilterQuery(
        [
          ...(filterPacketTypeByID?.length
            ? [
                {
                  key: "summary._productType",
                  values: filterPacketTypeByID,
                  valuesNotSelected: notSelectedPacketTypesId,
                },
              ]
            : []),
          ...(filterProductSubTypeByID?.length
            ? [
                {
                  key: "summary._productSubType",
                  values: filterProductSubTypeByID,
                  valuesNotSelected: notSelectedProductSubTypesId,
                },
              ]
            : []),
          ...(filterIntensityByID?.length
            ? [
                {
                  key: "intensityLevels",
                  values: filterIntensityByID,
                },
              ]
            : []),
        ],
        true
      )
    );
    toggleDrawer();
  };

  useEffect(() => {
    if (noneFilterSelected) {
      dispatch(applyFilter([]));
      dispatch(setFilterTotalItems(0));
    } else {
      if (filterPage > 0) {
        dispatch(
          applyFilter([...filteredPackets, ...(packetsData?.data || [])])
        );
      } else {
        dispatch(applyFilter(packetsData?.data || []));
      }

      dispatch(setFilterTotalItems(packetsData?.pagination?.total || 0));
    }
  }, [packetsData]);

  useEffect(() => {
    // clear fiter query when filter cleared
    if (filteredPackets.length === 0) setFilterQuery("");
  }, [filteredPackets]);

  useEffect(() => {
    dispatch(setFilterLoading(packetIsFetching));
  }, [packetIsFetching]);

  const filterWrapperStyle = (theme: Theme) => ({
    p: 4,
    minWidth: 400,
    height: "100%",
    overflow: "auto",
    background:
      "linear-gradient(white 30%, #F7F6F5), linear-gradient(#F7F6F5, white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#F7F6F5",
    backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
    backgroundAttachment: "local, local, scroll, scroll",
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "10px",
      borderRadius: "3px",
      backgroundColor: "#fafafa",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4d4d4d",
      borderRadius: "3px",
    },
  });

  const compundSelectedProductType = () => {
    if (selectedProductTypes && filterPacketTypeByID.length)
      return [selectedProductTypes, ...filterPacketTypeByID];
    else if (selectedProductTypes) {
      return [selectedProductTypes];
    } else if (filterPacketTypeByID.length) {
      return [...filterPacketTypeByID];
    }
  };

  return (
    <Box
      sx={{
        [tm.breakpoints.down("md")]: {
          width: "100%",
        },
      }}
    >
      <Button
        onClick={toggleDrawer}
        variant="outlined"
        size="large"
        fullWidth={!mediumBreakpoint}
        color="primary"
        startIcon={<FilterLines width={21} height={21} />}
        endIcon={
          !!selectedFilterCount && (
            <Badge
              badgeContent={selectedFilterCount}
              color="primary"
              sx={{
                mx: 2,
              }}
            />
          )
        }
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            verticalAlign: "center",
            alignContent: "center",
            alighSelf: "center",
            paddingTop: "5px",
          }}
        >
          {t("products:filter.button")}
        </Box>
      </Button>
      <Container>
        <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
          <Box sx={filterWrapperStyle}>
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography component="h3" sx={{ fontWeight: 500, fontSize: 20 }}>
                {t("common:filter")}
              </Typography>
              <IconButton onClick={toggleDrawer}>
                <XClose width={24} height={24} />
              </IconButton>
            </Box>
            <Box>
              {!!showPacketTypes && !!sortedPacketTypes && (
                <FilterCategory
                  type="filterPacketTypeByID"
                  title={t("common:product-types")}
                  items={sortedPacketTypes}
                  onChange={handleFilterByPacketType}
                />
              )}

              {/* TODO: check this part after running  */}
              {!!sortedProductSubTypes && (
                <FilterCategory
                  type="filterProductSubTypeByID"
                  title={t("common:product-sub-types")}
                  items={sortedProductSubTypes}
                  onChange={handleFilterByProductSubTypesChange}
                  checkboxDispaly={showPacketTypes ? "disable" : "hide"}
                  selectedProductTypes={compundSelectedProductType()}
                />
              )}

              <FilterCategory
                type="filterIntensityByID"
                title={t("common:intensity")}
                items={INTENSITIES}
                onChange={handleFilterByIntentisitiesChange}
                intensity
              />
            </Box>
          </Box>
          <Box
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              right: 0,
              py: 3,
              px: 4,
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            }}
          >
            <Button
              variant="contained"
              sx={{ py: 2 }}
              fullWidth
              onClick={handleFilterClick}
            >
              <Typography variant="h6" sx={{ fontWeight: 500, fontSize: 16 }}>
                {t("common:apply-filter")}
              </Typography>
            </Button>
          </Box>
        </Drawer>
      </Container>
    </Box>
  );
};

export default Filter;
