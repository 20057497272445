import { Skeleton } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  useGetProductsQuery,
  useGetProductSubTypesQuery,
} from "src/store/apis";
import type { CustomPacketItems } from "src/types/Packet";
import { combineNamesByLocale } from "src/utils";
import {
  generatePacketNameByProductItems,
  generatePacketNameBySubtypeAndAmount,
} from "src/utils/packet";

interface PacketNameProps {
  packetItems?: IPacket["items"];
  customPacketItems?: CustomPacketItems;
  breakLine?: boolean;
}

// TODO: refactor to 2 components.
export const PacketName = ({
  packetItems,
  customPacketItems,
  breakLine = false,
}: PacketNameProps) => {
  const { i18n } = useTranslation();

  const {
    data: subTypes,
    isLoading: isSubTypesLoading,
    isSuccess: isSubTypesSuccess,
  } = useGetProductSubTypesQuery();
  const {
    data: products,
    isLoading: isProductsLoading,
    isSuccess: isProductsSuccess,
  } = useGetProductsQuery();

  if (isSubTypesLoading || isProductsLoading) {
    return (
      <Skeleton
        width="100%"
        sx={{
          minHeight: {
            md: 37,
            xs: 48,
          },
        }}
      />
    );
  }

  let nameLines: string[] = [];

  if (isSubTypesSuccess) {
    if (customPacketItems) {
      const transformedSubTypeAmounts = customPacketItems.map((_) => ({
        _subType: _._productSubType,
        amount: _.userSelectedQuantity,
      }));
      nameLines = generatePacketNameBySubtypeAndAmount(
        transformedSubTypeAmounts,
        subTypes,
        i18n.language as "tr" | "en"
      );
    }

    if (packetItems && isProductsSuccess) {
      nameLines = generatePacketNameByProductItems(
        packetItems,
        subTypes,
        products,
        i18n.language as "tr" | "en"
      );
    }
  }

  let name = "";
  if (breakLine) {
    name = nameLines.join(",\n");
  } else {
    name = combineNamesByLocale(nameLines, i18n.language); // combine names by locale
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{name}</>;
};
