import { SVGProps, memo } from "react";

const SvgBeijeDotPadsBackground = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="375"
    height="699"
    viewBox="0 0 375 699"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M412.132 341.586C420.659 451.015 444.459 577.661 362.613 650.609C278.261 725.789 151.467 697.216 41.4187 671.993C-68.5406 646.791 -195.183 617.33 -238.295 512.881C-280.074 411.66 -200.607 309.852 -147.597 214.057C-100.23 128.458 -53.4807 34.0586 40.8101 8.50587C138.631 -18.0035 243.248 19.9505 318.75 87.6769C389.314 150.973 404.76 246.974 412.132 341.586Z"
      fill="#841013"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M385.647 89.1451C421.17 84.8361 459.511 92.2289 483.201 119.172C508.091 147.478 516.497 188.765 504.807 224.663C494.095 257.559 459.529 271.711 428.579 286.908C397.28 302.275 364.864 320.742 331.845 309.588C294.213 296.876 257.51 267.354 253.284 227.691C249.312 190.414 287.907 166.773 313.746 139.726C335.062 117.414 355.087 92.852 385.647 89.1451Z"
      fill="#B52129"
    />
  </svg>
);

const Memo = memo(SvgBeijeDotPadsBackground);
export default Memo;
