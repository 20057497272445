import {
  Box,
  FormControlLabel,
  FormGroup,
  Typography,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "next-i18next";
import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "src/store";
import { PartialBy } from "src/types/Utilities";

import Intensity from "../Intensity";

type FilterCategoryProps = {
  type:
    | "filterPacketTypeByID"
    | "filterProductSubTypeByID"
    | "filterIntensityByID";
  title: string;
  items: PartialBy<IFilter, "sortOrder">[];
  intensity?: boolean;
  onChange?: any;
  selectedProductTypes?: string[];
  checkboxDispaly?: "disable" | "hide";
};

const FilterCategory = ({
  type,
  title,
  items,
  intensity,
  onChange,
  selectedProductTypes,
  checkboxDispaly = "disable",
}: FilterCategoryProps) => {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.up("md"));

  const filterByIDS = useSelector(
    (state: RootState) => state.FilterPacketSlice[type]
  );

  const isChecked = (id: string) => filterByIDS.includes(id);
  const checkboxDispalyCondition = (typeId: string) =>
    type === "filterProductSubTypeByID" &&
    !selectedProductTypes?.includes(typeId) &&
    !!selectedProductTypes?.length;

  const filteredItems =
    checkboxDispaly === "hide"
      ? items.filter(
          (item) => item?.typeId && !checkboxDispalyCondition(item.typeId)
        )
      : items;

  return (
    <Box mt={4}>
      <Typography component="h2" sx={{ fontWeight: 500, fontSize: 16 }} mb={3}>
        {title}
      </Typography>
      <Box>
        {filteredItems?.map((item, index) => (
          <Box key={index} display="flex" width="100%" alignItems="center">
            <FormGroup
              sx={{ marginRight: 2, width: breakpoint ? "14rem" : "60%" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked(
                      type === "filterPacketTypeByID"
                        ? // TODO: check with javad
                          item.productType!
                        : item.value
                    )}
                    onChange={() =>
                      onChange(
                        type === "filterPacketTypeByID"
                          ? item.productType
                          : item.value
                      )
                    }
                    disabled={
                      item?.typeId
                        ? checkboxDispalyCondition(item.typeId)
                        : undefined
                    }
                  />
                }
                label={
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography>{item.name[i18n.language]}</Typography>
                  </Box>
                }
              />
            </FormGroup>
            {intensity && <Intensity count={index} />}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FilterCategory;
