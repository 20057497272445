import { Droplets02 } from "@beije/react-icons";
import { FC } from "react";

type IntensityProps = {
  count: number;
};

const Intensity: FC<IntensityProps> = ({ count }) => (
  <div>
    <Droplets02 width={24} height={24} fill="#000" />
    <Droplets02
      width={24}
      height={24}
      fill={count >= 1 ? "#000" : "transparent"}
    />
    <Droplets02
      width={24}
      height={24}
      fill={count >= 2 ? "#000" : "transparent"}
    />
    <Droplets02
      width={24}
      height={24}
      fill={count >= 3 ? "#000" : "transparent"}
    />
  </div>
);

export default Intensity;
