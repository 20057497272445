import { SVGProps, memo } from "react";

const SvgRefresh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 20}
    height={props.height || 20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.6667 1.66797C11.6667 1.66797 12.3744 1.76907 15.3033 4.698C18.2322 7.62693 18.2322 12.3757 15.3033 15.3046C14.2656 16.3423 12.9994 17.0124 11.6667 17.3148M11.6667 1.66797L16.6667 1.66797M11.6667 1.66797L11.6667 6.66797M8.33333 18.3345C8.33333 18.3345 7.62563 18.2334 4.6967 15.3044C1.76777 12.3755 1.76777 7.62677 4.6967 4.69784C5.73443 3.66011 7.0006 2.99005 8.33333 2.68766M8.33333 18.3345L3.33333 18.3346M8.33333 18.3345L8.33333 13.3346"
      stroke="#343131"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgRefresh);
export default Memo;
