import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Box,
} from "@mui/material";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { MouseEventHandler } from "react";
import { useDispatch } from "react-redux";

import { useAnalytics } from "src/core/analytics";
import { openCartDrawer, addToCart } from "src/store/slices";
import { formatPrice } from "src/utils";

import { AddToCartButton } from "./AddToCartButton";
import {
  CardContentSecondarySx,
  CardContentSx,
  CardMediaSx,
  CardSx,
} from "./styles";
import { BackendImage } from "../../Common/BackendImage";

type OtherPacketProps = {
  item: IProduct;
  preview?: boolean;
  isOutOfStock?: boolean;
};

export const OtherPacket = ({
  item,
  preview,
  isOutOfStock,
}: OtherPacketProps) => {
  const { i18n, t } = useTranslation();
  const router = useRouter();
  const { customTrackers } = useAnalytics();
  const dispatch = useDispatch();

  const handleAddToBasketButtonClick: MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.stopPropagation();
    customTrackers.trackAddToCart({
      data: item,
      purchaseType: "oneTimePurchase",
      type: "product",
      quantity: 1,
    });
    dispatch(
      addToCart({
        data: item,
        purchaseType: "oneTimePurchase",
        type: "product",
        quantity: 1,
      })
    );
    dispatch(openCartDrawer());
  };

  const handleItemClick = () => {
    if (!preview) {
      router.push({
        pathname: `/product/${item?.pageIdentifier}`,
      });
    }
  };

  return (
    <Card
      sx={CardSx}
      style={{
        cursor: preview ? "default" : "pointer",
        overflow: "visible",
      }}
      onClick={handleItemClick}
    >
      <CardContent sx={CardContentSx}>
        <CardMedia sx={CardMediaSx}>
          <BackendImage
            src={{
              entity: "product",
              idType: "_id",
              id: item._id,
              variant: "card",
            }}
            loading="lazy"
            fill
            sizes="100vw"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </CardMedia>

        <CardContent
          sx={CardContentSecondarySx}
          style={{
            alignItems: preview ? "center" : undefined,
            padding: preview ? "24px 24px 16px" : "24px 24px 8px",
          }}
        >
          <Typography
            whiteSpace="break-spaces"
            component="h5"
            variant="mobileH4"
            sx={{
              width: "100%",

              // info: props declared manualy, because in drawer rendering out of context
              fontFamily: preview ? "Gordita" : undefined,
              fontWeight: preview ? 500 : undefined,
              fontSize: preview ? "1.125rem" : undefined,
              lineHeight: preview ? "1.5625rem" : undefined,
              opacity: preview ? 0.9 : undefined,
            }}
          >
            {item.name[i18n.language as "en" | "tr"]}
          </Typography>
        </CardContent>
        <CardContent
          sx={{
            paddingBottom: 0,
            paddingY: 0,
            paddingX: 3,
          }}
        >
          <Typography component="p" variant="body2">
            {formatPrice(item.price.oneTimePurchase)}
          </Typography>
        </CardContent>

        <CardActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: 3,
            gap: 1,
            width: "100%",
            paddingBottom: 0,
          }}
        >
          {isOutOfStock ? (
            <Typography
              component="p"
              variant="desktopSubtitle2"
              color="rgba(0, 0, 0, 0.26)"
              mb={1}
            >
              {t("packet:out-of-stock")}
            </Typography>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 1.5,
                flexGrow: 0,
              }}
            >
              <AddToCartButton
                onClick={handleAddToBasketButtonClick}
                packetId={item._id}
              />
            </Box>
          )}
        </CardActions>
      </CardContent>
    </Card>
  );
};
